import cx from 'classnames'
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  FC,
  forwardRef,
} from 'react'

import { LoadingSpinner } from './LoadingSpinner'

export interface IButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'size'> {
  'data-testid'?: string
  size?: 'lg'
  loading?: boolean
  icon?: FC<{ className?: string }>
}

export interface ISecondaryButtonProps extends Omit<IButtonProps, 'size'> {
  size?: 'lg' | 'square'
}

export interface ILinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  'data-testid'?: string
  size?: 'lg'
  loading?: boolean
  icon?: FC<{ className?: string }>
}

export interface ISecondaryButtonLinkProps extends Omit<ILinkProps, 'size'> {
  size?: 'lg' | 'square'
}

export const PrimaryButton = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      'data-testid': dataTestId,
      children,
      className,
      disabled,
      loading,
      onClick,
      size,
      icon: Icon,
      type = 'button',
      ...props
    },
    ref
  ) => {
    return (
      <button
        {...props}
        ref={ref}
        data-testid={dataTestId}
        disabled={disabled || loading}
        onClick={onClick}
        type={type}
        className={cx(
          className,
          'rounded-md px-[22px] leading-snug transition-colors',
          'border border-teal-4 bg-teal-4 text-white',
          'hover:border-teal-5 hover:bg-teal-5 focus:border-teal-5 focus:bg-teal-5',
          'disabled:border-transparent disabled:bg-grey-3 disabled:text-grey-6',
          {
            relative: loading,
            'py-8': !size,
            'py-16 font-semibold': size === 'lg',
          }
        )}
      >
        {loading ? <LoadingSpinner /> : null}
        <span
          className={cx('min-w-[90px] transition-opacity', {
            'opacity-0': loading,
            'inline-block': !Icon,
            'flex items-center justify-center': Icon,
          })}
        >
          {Icon ? (
            <Icon
              className={cx('mr-10 rect-22', {
                'stroke-white': !disabled,
                'stroke-grey-6': disabled,
              })}
              aria-hidden="true"
            />
          ) : null}
          {children}
        </span>
      </button>
    )
  }
)

PrimaryButton.displayName = 'PrimaryButton'

export const PrimaryButtonLink = forwardRef<HTMLAnchorElement, ILinkProps>(
  (
    {
      className,
      children,
      'data-testid': dataTestId,
      href,
      size,
      icon: Icon,
      onClick,
    },
    ref
  ) => {
    return (
      <a
        ref={ref}
        href={href}
        onClick={onClick}
        data-testid={dataTestId}
        className={cx(
          className,
          'rounded-md px-[22px] text-center leading-snug transition-colors',
          'border border-teal-4 bg-teal-4 text-white',
          'hover:border-teal-5 hover:bg-teal-5 focus:border-teal-5 focus:bg-teal-5',
          {
            'py-8': !size,
            'py-16 font-semibold': size === 'lg',
          }
        )}
      >
        <span
          className={cx('min-w-[90px]', {
            'inline-block': !Icon,
            'flex items-center justify-center': Icon,
          })}
        >
          {Icon ? (
            <Icon className="mr-10 stroke-white rect-22" aria-hidden="true" />
          ) : null}
          {children}
        </span>
      </a>
    )
  }
)
PrimaryButtonLink.displayName = 'PrimaryButtonLink'

export const SecondaryButton = forwardRef<
  HTMLButtonElement,
  ISecondaryButtonProps
>(
  (
    {
      className,
      onClick,
      children,
      disabled,
      'data-testid': dataTestId,
      loading,
      size,
      icon: Icon,
      type = 'button',
      ...props
    },
    ref
  ) => {
    return (
      <button
        {...props}
        ref={ref}
        data-testid={dataTestId}
        disabled={disabled || loading}
        onClick={onClick}
        type={type}
        className={cx(
          className,
          'rounded-md leading-snug transition-colors',
          'border border-teal-4 text-teal-4',
          'hover:border-teal-5 hover:bg-teal-1 hover:text-teal-5 focus:border-teal-5 focus:bg-teal-1 focus:text-teal-5',
          'disabled:border-grey-6 disabled:bg-grey-3 disabled:text-grey-6',
          {
            relative: loading,
            'px-[22px] py-8': !size,
            'px-[22px] py-16 font-semibold': size === 'lg',
            'p-8': size === 'square',
          }
        )}
      >
        {loading ? <LoadingSpinner /> : null}
        <span
          className={cx('transition-opacity', {
            'opacity-0': loading,
            'min-w-[90px]': size !== 'square',
            'inline-block': !Icon,
            'flex items-center justify-center': Icon,
          })}
        >
          {Icon ? (
            <Icon
              className={cx('rect-22', {
                'stroke-teal-4': !disabled,
                'stroke-grey-6': disabled,
                'mr-10': size !== 'square',
              })}
              aria-hidden="true"
            />
          ) : null}
          {size === 'square' ? (
            <span className="sr-only">{children}</span>
          ) : (
            children
          )}
        </span>
      </button>
    )
  }
)

SecondaryButton.displayName = 'SecondaryButton'

export const SecondaryButtonLink = forwardRef<
  HTMLAnchorElement,
  ISecondaryButtonLinkProps
>(
  (
    {
      className,
      children,
      'data-testid': dataTestId,
      href,
      size,
      icon: Icon,
      onClick,
    },
    ref
  ) => {
    return (
      <a
        ref={ref}
        href={href}
        onClick={onClick}
        data-testid={dataTestId}
        className={cx(
          className,
          'rounded-md text-center leading-snug transition-colors',
          'border border-teal-4 text-teal-4',
          'hover:border-teal-5 hover:bg-teal-1 hover:text-teal-5 focus:border-teal-5 focus:bg-teal-1 focus:text-teal-5',
          {
            'px-[22px] py-8': !size,
            'px-[22px] py-16 font-semibold': size === 'lg',
            'p-8': size === 'square',
          }
        )}
      >
        <span
          className={cx({
            'min-w-[90px]': size !== 'square',
            'inline-block': !Icon,
            'flex items-center justify-center': Icon,
          })}
        >
          {Icon ? (
            <Icon
              className={cx('stroke-teal-4 rect-22 ', {
                'mr-10': size !== 'square',
              })}
              aria-hidden="true"
            />
          ) : null}
          {size === 'square' ? (
            <span className="sr-only">{children}</span>
          ) : (
            children
          )}
        </span>
      </a>
    )
  }
)

SecondaryButtonLink.displayName = 'SecondaryButtonLink'

export const TertiaryButton = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      'data-testid': dataTestId,
      children,
      className,
      disabled,
      onClick,
      type = 'button',
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        data-testid={dataTestId}
        disabled={disabled}
        onClick={onClick}
        type={type}
        className={cx(
          className,
          'border-b-2 border-teal-3 font-semibold text-teal-3 transition-colors',
          'hover:border-teal-5 hover:text-teal-5 focus:border-teal-5 focus:text-teal-5',
          'disabled:border-grey-6 disabled:text-grey-6'
        )}
      >
        {children}
      </button>
    )
  }
)

TertiaryButton.displayName = 'TertiaryButton'

export const TertiaryButtonLink = forwardRef<HTMLAnchorElement, ILinkProps>(
  ({ className, children, 'data-testid': dataTestId, href, onClick }, ref) => {
    return (
      <a
        ref={ref}
        href={href}
        onClick={onClick}
        data-testid={dataTestId}
        className={cx(
          className,
          'inline-block border-b-2 border-teal-3 font-semibold text-teal-3 transition-colors',
          'hover:border-teal-5 hover:text-teal-5 focus:border-teal-5 focus:text-teal-5'
        )}
      >
        {children}
      </a>
    )
  }
)

TertiaryButtonLink.displayName = 'TertiaryButtonLink'

export const TanButton: FC<IButtonProps> = ({
  'data-testid': dataTestId,
  children,
  className,
  disabled,
  loading,
  onClick,
  size,
  type = 'button',
}) => {
  return (
    <button
      data-testid={dataTestId}
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={cx(
        className,
        'rounded-md leading-snug transition-colors',
        'border border-tan-3 bg-tan-3 text-white',
        'hover:border-tan-3 hover:bg-tan-3 focus:border-tan-3 focus:bg-tan-3',
        'disabled:bg-bg-tan-3 disabled:cursor-not-allowed disabled:border-tan-3',
        {
          relative: loading,
          'px-[22px] py-8': !size,
          'px-[22px] py-16': size === 'lg',
        }
      )}
    >
      {loading ? <LoadingSpinner /> : null}
      <span
        className={cx('inline-block min-w-[90px] transition-opacity', {
          'opacity-0': loading,
        })}
      >
        {children}
      </span>
    </button>
  )
}
